import { OfferItem, useCancelApproveExpertOffer } from "@/services/api/offer";
import createStyle from "@/utils/createStyle";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";
import SentimentSatisfiedIcon from "@mui/icons-material/SentimentSatisfied";
import { Button, CircularProgress, Theme, Typography } from "@mui/material";
import { Stack, SxProps } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";

const styles = createStyle({
  container: {
    padding: "0.75rem 1rem",
    width: "100%",
    borderRadius: "0.5rem",
    marginTop: "1rem",
    boxShadow: "0px 2px 8px 0px #00000029"
  },
  progressLabel: {
    fontSize: "0.75rem",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)"
  },
  descriptionText: {
    color: "#393939",
    fontSize: "1rem",
    fontWeight: "600"
  },
  cancelBtn: {
    fontSize: "0.875rem",
    textDecoration: "underline"
  }
});

const StatusCMP = ({ notAssigned }: { notAssigned: boolean }) => {
  return (
    <Stack direction="row" alignItems="center">
      <Typography
        fontSize="0.6875rem"
        marginRight="0.25rem"
        color={(th) =>
          notAssigned ? th.palette.error.main : th.palette.success.main
        }
      >
        {notAssigned ? "به کارشناس دیگری واگذار شد" : "به نام شما ثبت شد"}
      </Typography>
      {notAssigned ? (
        <SentimentDissatisfiedIcon
          color="error"
          sx={{ width: "1.5rem", height: "1.5rem" }}
        />
      ) : (
        <SentimentSatisfiedIcon
          color="success"
          sx={{ width: "1.5rem", height: "1.5rem" }}
        />
      )}
    </Stack>
  );
};

export const LoadingOfferItem = ({
  status,
  final_approval_date,
  auction_time,
  brand_model,
  visit_time,
  id,
  time_slot
}: OfferItem) => {
  const endTime = useMemo(
    () => new Date(final_approval_date ?? ""),
    [final_approval_date]
  );
  const remainedTime = useMemo(
    () => (endTime.getTime() - Date.now()) / 1000,
    [endTime]
  );

  const intervalRef = useRef<NodeJS.Timer>();
  const [time, setTime] = useState<number>(0);
  const [progress, setProgress] = useState(100);
  const isToday = time_slot?.shift.date
    ? new Date(time_slot?.shift.date).toDateString() ===
      new Date().toDateString()
    : false;
  const containerSx = useMemo<SxProps<Theme>>(
    () => ({
      ...styles.container,
      backgroundColor: (th) =>
        status === "auction"
          ? th.palette.common.white
          : status === "assigned"
          ? "#EBF8F1"
          : "#FFF4F4"
    }),
    [status]
  );

  const { mutate: cancelApprove } = useCancelApproveExpertOffer();

  useEffect(() => {
    setTime(remainedTime);
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    intervalRef.current = setInterval(() => {
      setTime((prev) => (prev - 1 < 0 ? prev : prev - 1));
    }, 1000);
    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current);
    };
  }, [remainedTime]);

  useEffect(() => {
    if (!auction_time) {
      setProgress(0);
      return;
    }
    setProgress((time / +auction_time) * 100);
  }, [time, auction_time]);

  return (
    <Stack sx={containerSx}>
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" alignItems="center">
          <Typography fontSize="1rem" fontWeight="700">
            {time_slot?.shift.date
              ? `${isToday ? "امروز" : "فردا"} - ${visit_time?.slice(0, 5)}`
              : "-"}
          </Typography>
          {status === "auction" && (
            <Button
              variant="text"
              color="error"
              sx={styles.cancelBtn}
              onClick={() => {
                cancelApprove({ offerId: id });
              }}
            >
              لغو آمادگی
            </Button>
          )}
        </Stack>
        {status !== "auction" ? (
          <StatusCMP notAssigned={status === "not_assigned"} />
        ) : time >= 1 ? (
          <Stack position="relative" height="2rem" width="2rem">
            <CircularProgress
              variant="determinate"
              value={progress}
              size="2rem"
            />
            <Typography sx={styles.progressLabel}>{time.toFixed(0)}</Typography>
          </Stack>
        ) : (
          <CircularProgress size="2rem" />
        )}
      </Stack>
      <Typography sx={styles.descriptionText}>{brand_model}</Typography>
    </Stack>
  );
};
