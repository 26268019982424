import { httpRest } from "@/services/http-clients";
import axios, { AxiosError } from "axios";
import {
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
  useQuery
} from "react-query";
import { VehicleType } from "./searchCar";

export enum INSPECTION_ORDER_STATE {
  STATE_REQUESTED = "R",
  STATE_ASSIGNED = "A",
  STATE_CALLED = "C",
  STATE_OFFER = "P",
  STATE_CONFIRMED_ASSIGNED = "O",
  STATE_EXPERT_EN_ROUTE = "E",
  STATE_EXPERT_ARRIVED = "I",
  STATE_EXPERT_STARTED = "S",
  STATE_INSPECTION_WAITING_FOR_QA = "Q",
  STATE_INSPECTION_WAITING_FOR_PRICE_ESTIMATION = "W",
  STATE_DONE = "D",
  STATE_ON_HOLD = "H",
  STATE_FAILURE = "F",
  STATE_UNKNOWN = "U"
}

export interface Shift {
  pk: number;
  start: string;
  end: string;
  date: string;
}

// GET assigned orders
export type AssiegedOrders = {
  id: number;
  shift: Shift;
  visit_place: string;
  visit_time: string;
  brand_model: string;
  selected_package_name: string;
  state: INSPECTION_ORDER_STATE;
  next_state: INSPECTION_ORDER_STATE;
  my_share: number;
  started_at: string | null;
  phone_number: string;
  report_data: unknown | null;
};

export const getAssignedOrdersURL =
  "/evaluation/v1/expert/fetch-assigned-orders/";

export const getAssignedOrders = async () => {
  const { data } = await httpRest<AssiegedOrders[]>({
    method: "GET",
    url: getAssignedOrdersURL
  });

  return data;
};

export const getAssignedOrdersKey = () => [getAssignedOrdersURL];

type GetAssignedOrdersUseQueryOptions = Omit<
  UseQueryOptions<AssiegedOrders[], unknown, AssiegedOrders[], string[]>,
  "queryKey" | "queryFn"
>;

export const useGetAssignedOrders = (
  options?: GetAssignedOrdersUseQueryOptions
) => {
  return useQuery({
    queryKey: getAssignedOrdersKey(),
    queryFn: getAssignedOrders,
    ...options
  });
};

export interface DoneOrdersParams {
  page: number;
  page_size: number;
}
export interface DoneOrders {
  brand_model: string;
  created: string;
  done_at: string;
  customer_name: string;
  phone_number: string;
  expert_share: number | string;
  id: number;
  selected_package_name: string;
  state: string;
  visit_place: string;
  visit_city: string;
  car_inspection_token: string;
}

export interface Response200 {
  count: number;
  results: DoneOrders[];
}

export const getDoneOrdersURL = "/evaluation/v1/expert/done-orders/";

export const getDoneOrders = async (params?: DoneOrdersParams) => {
  const { data } = await httpRest<Response200>({
    method: "GET",
    params,
    url: getDoneOrdersURL
  });

  return data;
};

export const getDoneOrdersKey = (params?: DoneOrdersParams) => [
  getDoneOrdersURL,
  ...(params ? [params] : [])
];

type GetDoneOrdersUseQueryOptions = Omit<
  UseQueryOptions<Response200, unknown, Response200, string[]>,
  "queryKey" | "queryFn"
>;

export const useGetDoneOrders = (
  params?: DoneOrdersParams,
  options?: GetDoneOrdersUseQueryOptions
) => {
  return useQuery({
    queryKey: getDoneOrdersKey(params) as any,
    queryFn: () => getDoneOrders(params),
    ...options
  });
};

// GET assigned order details
export interface AssiegedOrderDetails {
  id: number;
  shift: Shift;
  visit_place: string;
  car_inspection_token: string;
  carmaa_id: string;
  visit_time: string;
  brand_model: string;
  brand_model_slug: string;
  selected_package_name: string;
  state: INSPECTION_ORDER_STATE;
  next_state: INSPECTION_ORDER_STATE;
  my_share: number;
  started_at: string | null;
  phone_number: string;
  customer_name: string;
  report_fields: {
    apshen: 0 | 1;
    badane: 0 | 1;
    lastikha: 0 | 1;
    paid_way: 0 | 1;
    general_images: 0 | 1;
    sanad_va_madrak: 0 | 1;
    paper_report_code: 0 | 1;
    photo_panorama_url: 0 | 1;
    special_description: 0 | 1;
    cancel_or_penalty_pay: 0 | 1;
    quality_control_state: 0 | 1;
    technical_expert_opinion: 0 | 1;
    tormoz_farman_va_system_taligh: 0 | 1;
    fazaie_dakhel_va_tajhizate_barghi: 0 | 1;
    motor_va_system_enteghale_ghodrath: 0 | 1;
  };
  report_data: unknown | null;
}

export const getAssignedOrderDetailsURL = (id: string) =>
  `/evaluation/v1/expert/order/${id}/fetch-details/`;

export const getAssignedOrderDetails = async (id: string) => {
  const { data } = await httpRest<AssiegedOrderDetails[]>({
    method: "GET",
    url: getAssignedOrderDetailsURL(id)
  });

  return data;
};

export const getAssignedOrderDetailsKey = (id: string) => [
  getAssignedOrderDetailsURL(id)
];

type GetAssignedOrderDetailsUseQueryOptions = Omit<
  UseQueryOptions<
    AssiegedOrderDetails[],
    unknown,
    AssiegedOrderDetails[],
    string[]
  >,
  "queryKey" | "queryFn"
>;

export const useGetAssignedOrderDetails = (
  id: string,
  options?: GetAssignedOrderDetailsUseQueryOptions
) => {
  return useQuery({
    queryKey: getAssignedOrderDetailsKey(id),
    queryFn: () => getAssignedOrderDetails(id),
    enabled: !!id,
    ...options
  });
};

// POST assigned order to next step
export interface PostAssiegedOrderToNextStepRequest {
  new_step: INSPECTION_ORDER_STATE;
  id: string;
}

export const postAssiegedOrderToNextStepURL = (id: string) =>
  `/evaluation/v1/expert/order/${id}/move-to-next-step/`;

export const postAssinedOrderToNextStep = async (
  req: PostAssiegedOrderToNextStepRequest
) => {
  const { data } = await httpRest({
    method: "POST",
    url: postAssiegedOrderToNextStepURL(req.id),
    data: { new_step: req.new_step }
  });

  return data;
};

type PostAssiegedOrderToNextStepOptions = Omit<
  UseMutationOptions<
    unknown,
    unknown,
    PostAssiegedOrderToNextStepRequest,
    unknown
  >,
  "mutateFn"
>;

export const usePostAssinedOrderToNextStep = (
  options?: PostAssiegedOrderToNextStepOptions
) => {
  return useMutation({ mutationFn: postAssinedOrderToNextStep, ...options });
};

// POST inspection report
export interface PostInspectionReportRequest {
  order_id: string;
  section: string;
  data: unknown;
}

export interface InspectionReport200 {
  data: string;
  update_schema: boolean;
}

export const postInspectionReportURL = (order_id: string) => {
  return `/evaluation/v1/expert/order/${order_id}/update-report-section/`;
};

export const postInspectionReport = async (
  req: PostInspectionReportRequest
) => {
  const { data } = await httpRest<InspectionReport200>({
    method: "POST",
    url: postInspectionReportURL(req.order_id),
    data: { data: { [req.section]: req.data }, section: req.section }
  });

  return data;
};

type PostInspectionReportOptions = Omit<
  UseMutationOptions<unknown, unknown, PostInspectionReportRequest, unknown>,
  "mutationFn"
>;

export const usePostInspectionReport = (
  options?: PostInspectionReportOptions
) => {
  return useMutation(postInspectionReport, options);
};

// POST schema changed
export interface PostSchemaChangedRequest {
  order_id: string;
}

export const postSchemaChangedURL = (order_id: string) => {
  return `/evaluation/v1/expert/inspection/${order_id}/schema/update/`;
};

export const postSchemaChanged = async (req: PostSchemaChangedRequest) => {
  const { data } = await httpRest({
    method: "POST",
    url: postSchemaChangedURL(req.order_id)
  });

  return data;
};

type PostSchemaChangedOptions = Omit<
  UseMutationOptions<unknown, unknown, PostSchemaChangedRequest, unknown>,
  "mutationFn"
>;

export const usePostSchemaChanged = (options?: PostSchemaChangedOptions) => {
  return useMutation(postSchemaChanged, options);
};

// POST create inspection report
export interface PostCreateInspectionReportRequest {
  order_id: string;
}

export const postCreateInspectionReportURL = (order_id: string) => {
  return `/evaluation/v1/expert/order/${order_id}/create-report/`;
};

export const postCreateInspectionReport = async (
  req: PostCreateInspectionReportRequest
) => {
  const { data } = await httpRest({
    method: "POST",
    url: postCreateInspectionReportURL(req.order_id)
  });

  return data;
};

type PostCreateInspectionReportOptions = Omit<
  UseMutationOptions<
    unknown,
    unknown,
    PostCreateInspectionReportRequest,
    unknown
  >,
  "mutationFn"
>;

export const usePostCreateInspectionReport = (
  options?: PostCreateInspectionReportOptions
) => {
  return useMutation(postCreateInspectionReport, options);
};

// PUT image uploader
export interface PutImageUploaderReq {
  imageName: string;
  content: File;
}

export type PutImageUploaderRes = string;

export const putImageUploaderURL = (req: PutImageUploaderReq) =>
  `https://api.karnameh.com/image-service/v1/upload-permanent/carinspection/${req.imageName}`;

export const putImageUploader = async (req: PutImageUploaderReq) => {
  const { data } = await axios<PutImageUploaderRes>({
    method: "POST",
    url: putImageUploaderURL(req),
    data: req.content,
    maxBodyLength: Infinity,
    headers: {
      "Content-Type": "image/jpeg"
    }
  });

  return data;
};

type PutImageUploaderOptions = Omit<
  UseMutationOptions<unknown, unknown, PutImageUploaderReq, unknown>,
  "mutationFn"
>;

export const usePutImageUploader = (options?: PutImageUploaderOptions) => {
  return useMutation(putImageUploader, options);
};

// Inspection QC
export interface InspectionSchemaParams {
  inspection_token: string;
}

export interface SectionRow {
  text: string;
  type: string;
  icon: string;
  action: {
    slug: string;
    type: string;
    value: string;
  };
  has_divider: boolean;
}

export interface InspectionSchema {
  schema: SectionRow[];
}
export const getInspectionSchemaURL = (id: string) =>
  `/evaluation/v1/expert/inspection/${id}/schema/`;

export const getInspectionSchema = async (id: string) => {
  const { data } = await httpRest<InspectionSchema>({
    method: "GET",
    url: getInspectionSchemaURL(id)
  });

  return data;
};

export const getInspectionSchemaKey = (id: string) => [
  getInspectionSchemaURL(id)
];

type GetInspectionSchemaUseQueryOptions = Omit<
  UseQueryOptions<InspectionSchema, unknown, InspectionSchema, string[]>,
  "queryKey" | "queryFn"
>;

export const useGetInspectionSchema = (
  id: string,
  options?: GetInspectionSchemaUseQueryOptions
) => {
  return useQuery({
    queryKey: getInspectionSchemaKey(id),
    queryFn: () => getInspectionSchema(id),
    enabled: !!id,
    ...options
  });
};

export interface InspectionSection {
  data: any;
  json_schema: any;
  ui_schema: any;
}

export const getInspectionSectionURL = (id: string, section: string) =>
  `/evaluation/v1/expert/inspection/${id}/section/${section}`;

export const getInspectionSection = async (id: string, section: string) => {
  const { data } = await httpRest<InspectionSection>({
    method: "GET",
    url: getInspectionSectionURL(id, section)
  });

  return data;
};

export const getInspectionSectionKey = (id: string, section: string) => [
  getInspectionSectionURL(id, section)
];

type GetInspectionSectionUseQueryOptions = Omit<
  UseQueryOptions<InspectionSection, unknown, InspectionSection, string[]>,
  "queryKey" | "queryFn"
>;

export const useGetInspectionSection = (
  id: string,
  section: string,
  options?: GetInspectionSectionUseQueryOptions
) => {
  return useQuery({
    queryKey: getInspectionSectionKey(id, section),
    queryFn: () => getInspectionSection(id, section),
    enabled: !!id,
    ...options
  });
};

// GET Work LOad of Expert
export interface WorkLoadResponse {
  state: string;
  required: number;
  current: number;
  average: number;
}

export const getWorkLoadURL = () => `/evaluation/v1/expert/workload/`;

export const getWorkLoad = async () => {
  const { data } = await httpRest<WorkLoadResponse>({
    method: "GET",
    url: getWorkLoadURL()
  });

  return data;
};

export const getWorkLoadKey = () => [getWorkLoadURL()];

type GetWorkLoadUseQueryOptions = Omit<
  UseQueryOptions<WorkLoadResponse, unknown, WorkLoadResponse, string[]>,
  "queryKey" | "queryFn"
>;

export const useGetWorkLoad = (options?: GetWorkLoadUseQueryOptions) => {
  return useQuery({
    queryKey: getWorkLoadKey(),
    queryFn: getWorkLoad,
    ...options
  });
};

// GET Packages
export type PackagesParams = {
  brand_model: string;
  city_id: number;
  is_bo: number;
  vehicle_type: string;
  is_available_on_app: number;
};

export type Packages = {
  data: {
    address: string;
    body_color_report: boolean;
    description: string;
    discount: number;
    duration: string;
    guarantee: string;
    in_person: boolean;
    latitude: number;
    longitude: number;
    pk: string;
    price: number;
    price_estimation_report: boolean;
    technical_report: boolean;
    title: string;
    slug?: string;
  }[];
};

export const getPackagesURL = "/evaluation/v1/packages/";

export const getPackages = async (params: PackagesParams) => {
  const { data } = await httpRest<Packages>({
    method: "GET",
    params,
    url: getPackagesURL
  });

  return data;
};

export const getPackagesKey = () => [getPackagesURL];

type GetPackagesUseQueryOptions = Omit<
  UseQueryOptions<Packages, unknown, Packages, string[]>,
  "queryKey" | "queryFn"
>;

export const useGetPackages = (
  params: PackagesParams,
  options?: GetPackagesUseQueryOptions
) => {
  return useQuery({
    queryKey: getPackagesKey(),
    queryFn: () => getPackages(params),
    ...options
  });
};

// GET Time Slots
export type TimeslotsParams = {
  city_id?: number;
  brand_model?: string;
  package_id?: number;
  vehicle_type?: VehicleType;
};

export type Timeslots = {
  data: {
    end: string;
    final_price?: number;
    is_immediate: boolean;
    pk: number;
    price_diff?: number;
    price_diff_reason?: number;
    start: string;
    start_timestamp: number;
    title: string;
    available_center: boolean;
    available_in_person: boolean;
  }[];
};

export const getTimeslotsURL = "/evaluation/v1/service_time/";

export const getTimeslots = async (params: TimeslotsParams) => {
  const { data } = await httpRest<Timeslots>({
    method: "GET",
    params,
    url: getTimeslotsURL
  });

  return data;
};

export const getTimeslotsKey = () => [getTimeslotsURL];

type GetTimeslotsUseQueryOptions = Omit<
  UseQueryOptions<Timeslots, unknown, Timeslots, string[]>,
  "queryKey" | "queryFn"
>;

export const useGetTimeslots = (
  params: TimeslotsParams,
  options?: GetTimeslotsUseQueryOptions
) => {
  return useQuery({
    queryKey: getTimeslotsKey(),
    queryFn: () => getTimeslots(params),
    ...options
  });
};

// POST reserve order
export interface PostReserveOrderRequest {
  shift_id: number;
  platform: string;
  visit_time: string;
  selected_package: number;
  customer_name: string;
  phone_number: string;
  visit_place: number;
  brand_model: string;
  discount_code?: string;
}

export const postReserveOrderURL = `/evaluation/v1/expert/order/inspection-app/reserve/`;

export const postReserveOrder = async (body: PostReserveOrderRequest) => {
  const { data } = await httpRest({
    method: "POST",
    url: postReserveOrderURL,
    data: body
  });

  return data;
};

type PostReserveOrderOptions = Omit<
  UseMutationOptions<unknown, unknown, PostReserveOrderRequest, unknown>,
  "mutateFn"
>;

export const usePostReserveOrder = (options?: PostReserveOrderOptions) => {
  return useMutation({ mutationFn: postReserveOrder, ...options });
};

export type InspectionExpertTicketParams = {
  inspection: string;
  description: string;
  subject: string;
};

export type InspectionExpertTicketResponse = {
  created_at: string;
  id: number;
  inspection: number;
  modified_at: string;
  subject: string;
  description: string;
};

const createInspectionExpertTicket = (data: InspectionExpertTicketParams) => {
  return httpRest<InspectionExpertTicketResponse>({
    method: "POST",
    url: "/evaluation/v1/inspection-ticket/",
    data
  });
};

export const useCreateInspectionExpertTicket = (
  options?: Omit<
    UseMutationOptions<
      Awaited<ReturnType<typeof createInspectionExpertTicket>>,
      AxiosError<unknown>,
      InspectionExpertTicketParams
    >,
    "mutationFn"
  >
) => {
  return useMutation({ ...options, mutationFn: createInspectionExpertTicket });
};

type CheckDiscountCodeParams = {
  code: string;
  city_id: number;
  brand_model: string;
  selected_package_id: number;
  shift_id: number;
  customer_phone_number: string;
};

type CheckDiscountCodeResponse = {
  data: { price: number };
};

const checkDiscountCode = (params: CheckDiscountCodeParams) => {
  return httpRest<CheckDiscountCodeResponse>({
    method: "POST",
    url: "/discount/v1/check_code/",
    data: params
  }).then((res) => res.data);
};

export const useCheckDiscountCode = (
  options?: Omit<
    UseMutationOptions<
      Awaited<ReturnType<typeof checkDiscountCode>>,
      AxiosError<unknown>,
      CheckDiscountCodeParams,
      unknown
    >,
    "mutationFn"
  >
) => {
  return useMutation({ ...options, mutationFn: checkDiscountCode });
};
