import useAppSelector from "@/hooks/useAppSelector";
import {
  ApproveExpertOfferError,
  OfferItem,
  useApproveExpertOffer
} from "@/services/api/offer";
import { ErrorType } from "@/services/http-clients";
import { useAuthInfo } from "@/store/slices/auth";
import DriveEtaIcon from "@mui/icons-material/DriveEta";
import MopedIcon from "@mui/icons-material/Moped";
import { Button, Stack, Typography } from "@mui/material";
import { FC, useMemo } from "react";
import { styles } from "./styles";
interface Props extends OfferItem {
  approved: (failed: boolean, message: string) => void;
}

const Item: FC<Props> = (props) => {
  const {
    brand_model: brandModel,
    selected_package: { name: packageName },
    visit_place: visitPlace,
    time_slot,
    expert_share,
    id,
    visit_time,
    approved,
    expert_base_share,
    expert_change_share,
    cs_expert_change_share,
    vehicle_type
  } = props;

  const blockingState = useAppSelector((state) => state.offer.blockingState);

  const isInBlacklist = useMemo(
    () =>
      blockingState === "firstBlocking" || blockingState === "secondBlocking",
    [blockingState]
  );

  const { data: authData } = useAuthInfo();
  const { mutateAsync: approveMutate, isLoading: isApprovingOrder } =
    useApproveExpertOffer(id, time_slot?.id ?? "");
  const visitTimeText = useMemo(() => {
    // let result = `${start.substring(0, 2)} تا ${end.substring(0, 2)}`;
    let result = "";
    if (!time_slot?.shift.date) return "-";

    if (
      typeof time_slot?.shift.date === "string" &&
      /^\d{4}-\d{1,2}-\d{1,2}$/.test(time_slot?.shift.date)
    ) {
      const today = new Date();
      const shiftDate = new Date(time_slot?.shift.date);
      if (shiftDate.getDate() === today.getDate()) {
        result = `امروز - ${result}`;
      } else if (shiftDate.getDate() === today.getDate() + 1) {
        result = `فردا - ${result}`;
      } else if (shiftDate.getDate() === today.getDate() - 1) {
        result = `دیروز - ${result}`;
      } else {
        result = `${shiftDate.toLocaleDateString("fa")} - ${result}`;
      }
    }

    if (typeof visit_time === "string" && /^\d\d:\d\d:\d\d$/.test(visit_time)) {
      result = `${result} ${visit_time.substring(0, 5)}`;
    }

    return result;
  }, [time_slot?.shift.date, visit_time]);

  const submit = () => {
    if (isApprovingOrder) return;
    if (authData) {
      approveMutate()
        .then((message) => {
          approved(false, message);
        })
        .catch((err: ErrorType<ApproveExpertOfferError>) => {
          approved(
            true,
            err.response?.data?.error?.[0] ??
              (err.response?.data.response || "inspection not found")
          );
        });
    }
  };

  return (
    <Stack
      direction="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      sx={{
        ...styles.container,
        border: expert_change_share
          ? `2px solid #694FB4`
          : cs_expert_change_share
          ? `2px solid #0078FB`
          : "none",
        backgroundColor: expert_change_share
          ? "#F3F2F8"
          : cs_expert_change_share
          ? `2px solid #EBF9FC`
          : "transparent"
      }}
    >
      {/* time */}
      <Stack
        width="100%"
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Stack sx={{ mb: 1 }}>
          <Typography
            color="rgba(0,0,0,0.86)"
            fontWeight="bold"
            fontSize="18px"
            mb={1}
          >
            {visitTimeText}
          </Typography>
          {/* <Stack direction="row" alignItems="center">
            <Typography color="#6E6E6E" fontSize="14px">
              شماره سفارش:
            </Typography>
            <Typography
              color="rgba(0,0,0,0.86)"
              fontSize="14px"
              fontWeight="bold"
              ml={1}
            >
              {id}
            </Typography>
          </Stack> */}
        </Stack>
        {vehicle_type === "light_car" ? (
          <DriveEtaIcon sx={{ fontSize: "32px", color: "#008200" }} />
        ) : vehicle_type === "motor_cycle" ? (
          <MopedIcon sx={{ fontSize: "32px", color: "#694FB4" }} />
        ) : null}
      </Stack>

      {/* <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        mb={1}
      >
        <Typography
          color="rgba(0,0,0,0.86)"
          fontSize="14px"
          sx={{ bgcolor: "#EEEEEE", px: 2, py: 0.5, borderRadius: 30, my: 0.5 }}
        >
          {renderState(state as string)}
        </Typography>
      </Stack> */}

      {/* brand model */}
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        mb={1}
      >
        <Typography color="rgba(0,0,0,0.86)" fontSize="16px">
          {brandModel}
        </Typography>
      </Stack>

      {/* place */}
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        mb={1}
      >
        <Typography color="rgba(0,0,0,0.86)" fontSize="16px">
          {visitPlace}
        </Typography>
      </Stack>

      {/* package name */}
      <Stack
        width={"100%"}
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        mb={1}
      >
        <Typography color="rgba(0,0,0,0.86)" fontSize="16px">
          {packageName}
        </Typography>
      </Stack>
      {expert_change_share ? (
        <Stack
          sx={{
            backgroundColor: "#694FB4",
            color: "white",
            py: 1,
            px: 2,
            borderRadius: 6,
            fontSize: "0.7rem",
            fontWeight: 600
          }}
        >
          {Math.round((expert_change_share * 100) / (expert_base_share ?? 1))}{" "}
          درصد افزایش سهم کارشناس ویژه شیفت
        </Stack>
      ) : null}
      {cs_expert_change_share ? (
        <Stack
          sx={{
            backgroundColor: "#0078FB",
            color: "white",
            py: 1,
            px: 2,
            borderRadius: 6,
            fontSize: "0.7rem",
            fontWeight: 600
          }}
        >
          {Math.round(
            (cs_expert_change_share * 100) / (expert_base_share ?? 1)
          )}{" "}
          درصد افزایش سهم کارشناس ویژه کارشناسی معامله
        </Stack>
      ) : null}

      {/* footer */}
      <Stack
        width="100%"
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={1}
        sx={styles.footer}
      >
        <Stack>
          <Typography fontSize="0.875rem">
            {typeof expert_share === "number" && (
              <>
                {"سهم شما: "}
                <strong
                  style={{ whiteSpace: "nowrap" }}
                >{`${expert_share.toLocaleString("fa-IR")} تومان`}</strong>
              </>
            )}
          </Typography>

          {expert_base_share && expert_change_share ? (
            <Typography variant="caption" sx={{ color: "gray" }}>
              {`(${(expert_change_share ?? 0).toLocaleString("fa-IR")} + ${(
                expert_base_share ?? 0
              ).toLocaleString("fa-IR")} تومان)`}
            </Typography>
          ) : null}
        </Stack>

        <Button
          disabled={isInBlacklist}
          onClick={submit}
          sx={styles.acceptButton}
        >
          اعلام آمادگی
        </Button>
      </Stack>
    </Stack>
  );
};

export default Item;
