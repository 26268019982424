import { useMutation, UseMutationOptions, useQuery } from "react-query";
import { httpRest } from "../http-clients";
import { queryClient } from "@/providers/AppQueryProvider";
import { AxiosError } from "axios";

export interface OfferItem {
  id: number;
  price: string;
  brand_model: string;
  selected_package: {
    id: number;
    name: string;
    slug: string;
  };
  time_slot: {
    id: number;
    state: number;
    shift: {
      id: number;
      date: string;
      start: string;
      end: string;
    };
  } | null;
  district: string | number | null;
  visit_time: string | null;
  expert_share: number | undefined;
  visit_place: string | null;
  visit_city: string | number | null;
  expert_base_share?: number | null;
  cs_expert_change_share?: number | null;
  expert_change_share?: number | null;
  offer_sent_at?: string;
  state?: string;
  vehicle_type?: string | null;
  status?: "auction" | "offer" | "assigned" | "not_assigned" | null;
  final_approval_date?: string | null;
  auction_time: string | null;
}

export interface ExpertOfferResponse {
  orders: OfferItem[];
  blacklist: {
    cancellation_count?: number;
    time?: string;
  };
  current_time?: {
    current_time?: string;
  };
}

interface ApproveExpertOfferResponse {
  response: string;
}

export interface ApproveExpertOfferError {
  response: string;
  error?: string[];
}

export const getExpertOffers = async () => {
  return httpRest<ExpertOfferResponse>({
    method: "GET",
    url: "/inspection/v1/expert/orders"
  });
};

export const getExpertOffersKey = "getExpertOffers";

export const useGetOffers = () => {
  return useQuery({
    queryKey: [getExpertOffersKey],
    queryFn: async () => {
      try {
        const res = await getExpertOffers();
        return res.data;
      } catch {
        return {} as ExpertOfferResponse;
      }
    },
    refetchInterval: 4_000 // in miliseconds
  });
};

export const postApproveExpertOffer = (
  offerId: number,
  timeSlot: number | string
) => {
  const formData = new FormData();

  formData.append("time_slot", timeSlot.toString());

  return httpRest<ApproveExpertOfferResponse>({
    method: "POST",
    url: `/inspection/v1/order/${offerId}/approve`,
    data: formData
  });
};

export const approveExpertOfferKey = "postApproveExpertOffer";

export const useApproveExpertOffer = (
  offerId: number,
  timeSlot: string | number
) => {
  return useMutation({
    mutationKey: [approveExpertOfferKey],
    mutationFn: () => {
      return postApproveExpertOffer(offerId, timeSlot).then(
        (res) => res.data.response
      );
    }
  });
};

export const cancelApproveExpertOffer = ({ offerId }: { offerId: number }) => {
  return httpRest<ApproveExpertOfferResponse>({
    method: "POST",
    url: `/inspection/v1/order/${offerId}/cancel-approve`
  }).then((res) => res.data.response);
};

export const cancelApproveExpertOfferKey = "postCancelApproveExpertOffer";

export const useCancelApproveExpertOffer = (
  options?: Omit<
    UseMutationOptions<
      Awaited<ReturnType<typeof cancelApproveExpertOffer>>,
      AxiosError<unknown>,
      { offerId: number },
      unknown
    >,
    "mutationFn"
  >
) => {
  return useMutation({
    ...options,
    mutationFn: cancelApproveExpertOffer
  });
};
